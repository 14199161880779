export default (state, action) => {
  switch (action.type) {
    case "LOGIN_SELLER":
      return { ...state, currentUser: { loading: true, error: "" } };
    case "LOGIN_SELLER_SUCCESS":
      return {
        ...state,
        currentUser: {
          loading: false,
          token: action.payload.token,
          user: action.payload.user,
          ioSocket: action.payload.ioSocket,
          error: "",
        },
      };
    case "LOGIN_SELLER_ERROR":
      return {
        ...state,
        currentUser: {
          loading: false,
          token: null,
          user: null,
          ioSocket: null,
          error: action.payload.message,
        },
      };
    case "LOGOUT_SELLER":
      localStorage.removeItem("token");
      return {
        ...state,
        currentUser: {
          loading: false,
          token: null,
          user: null,
          ioSocket: null,
          error: "",
        },
      };
    case "RESET_PASSWORD":
      return { ...state, currentUser: { loading: true }, error: "" };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        currentUser: {
          loading: false,
          token: action.payload.token,
          user: action.payload.user,
          ioSocket: action.payload.ioSocket,
          error: "",
        },
      };
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        currentUser: {
          loading: false,
          token: null,
          user: null,
          ioSocket: null,
          error: action.payload.message,
        },
      };
    case "GET_INIT_SETTINGS":
      return {
        ...state,
        settings: {},
      };
    default:
      return { ...state };
  }
};
