import {
    ACTIVE_PRODUCT, AUCTIONS_EXAMPLE, AUCTION_BIDS, AUCTION_DETAILS,
    COUNTDOWN, HIGHEST_BID, LAST_BID_ID, STATUS_NAME, STATUS_TYPE,
    UPDATE_BIDS
} from './types';
const initialState = {
    auctionsExample: ['hi', 'hello'],
    auctionDetails: {},
    auctionBids: null,
    activeProduct: null,
    countdown: null,
    highestBid: null,
    statusType: null,
    statusName: '',
    bids: [],
    lastBidId: null
}
const AuctionStore = (state = initialState, action) => {
    switch (action.type) {
        case AUCTIONS_EXAMPLE:
            return {
                ...state,
                auctionsExamples: action.payload
            }
        case AUCTION_DETAILS:
            return {
                ...state,
                auctionDetails: action.payload
            }
        case AUCTION_BIDS:
            return {
                ...state,
                auctionBids: action.payload
            }
        case ACTIVE_PRODUCT:
            return {
                ...state,
                activeProduct: action.payload
            }
        case COUNTDOWN:
            return {
                ...state,
                countdown: action.payload
            }
        case HIGHEST_BID:
            return {
                ...state,
                highestBid: action.payload
            }
        case LAST_BID_ID:
            return {
                ...state,
                lastBidId: action.payload
            }
        case STATUS_TYPE:
            return {
                ...state,
                statusType: action.payload
            }
        case STATUS_NAME:
            return {
                ...state,
                statusName: action.payload
            }
        case UPDATE_BIDS:
            return {
                ...state,
                bids: action.payload
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default AuctionStore