import {
  AUCTIONS_EXAMPLE, AUCTION_DETAILS, AUCTION_BIDS,
  ACTIVE_PRODUCT, COUNTDOWN, HIGHEST_BID, STATUS_TYPE, STATUS_NAME,
  UPDATE_BIDS,
  LAST_BID_ID
} from "./types";
export const addCompanies = (value) => {
  return (dispatch) => dispatch({
    type: AUCTIONS_EXAMPLE,
    payload: value
  })
}
export const setAuctionDetails = (payload) => {
  return {
    type: AUCTION_DETAILS,
    payload
  };
};

export const setAuctionBids = (payload) => {
  return {
    type: AUCTION_BIDS,
    payload
  };
};

export const setActiveProduct = (payload) => {
  return {
    type: ACTIVE_PRODUCT,
    payload
  }
}

export const setCountdown = (payload) => {
  return {
    type: COUNTDOWN,
    payload
  }
}
export const setHighestBid = (payload) => {
  return {
    type: HIGHEST_BID,
    payload
  }
}
export const setLastBidId = (payload) => {
  return {
    type: LAST_BID_ID,
    payload
  }
}
export const setStatusType = (payload) => {
  return {
    type: STATUS_TYPE,
    payload
  }
}
export const setStatusName = (payload) => {
  return {
    type: STATUS_NAME,
    payload
  }
}
export const setBidsRedux = (payload) => {
  return {
    type: UPDATE_BIDS,
    payload
  }
}
